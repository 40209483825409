<template>
  <transition name="fade">
    <div
      class="flex fixed top-0 left-0 w-full h-full min-w-full  min-h-screen max-w-screen-lg z-50 bg-black bg-opacity-40 overflow-auto"
      v-if="isModalShowing"
      @click.self="hide()"
    >
      <div
        class="flex relative mx-auto my-auto bg-white border rounded shadow-2xl h-auto w-11/12 md:w-1/2 lg:w-1/3 min-w-min"
      >
        <Icon
          name="mdi:close"
          class="text-2xl absolute top-0 right-0 rounded text-a-neutral-lightest hover:text-a-neutral"
          @click="hide()"
        />
        <div class="flex flex-col p-4 w-full">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">


const isModalShowing = ref(false);

function show() {
  isModalShowing.value = true;
};
function hide() {
  isModalShowing.value = false;
};

defineExpose({
  show,
  hide,
})

</script>

<style scoped>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 0.3s ease-out;
}

.slidedown-enter {
  /* opacity: 0; */
  transform: translateY(-100%);
}

.slidedown-enter-active {
  transition: all 0.3s ease;
}

.slidedown-leave-to {
  /* opacity: 0; */
  transform: translateY(-100%);
}

.slidedown-leave-active {
  transition: all 0.3s ease;
}</style>
